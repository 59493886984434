.footer_container{
    position: relative;

}

.footer_container>hr{
    border: 1px solid var(--lightgray);
}

.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;

}

.social-links{
    display: flex;
    gap: 4rem;
}

.social-links>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}


.logo-footer>img{
    width: 10rem;
}

.blur-f-1{
    bottom: 0;
    width: 26rem;
    height: 12rem;
    right: 15%;
    filter: blur(200px);
    /* background: rgb(255, 247, 0); */

}

.blur-f-2{
    bottom: 0;
    left: 10%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(255, 115, 0);
}

.footer__copyright{
    align-items: center;
    justify-content: center;
    color: white;
}


@media screen and (max-width:768px){
    .footer__copyright{

        font-size: 12px;  

    }
    .footer{
        padding: 1rem;
    }
}

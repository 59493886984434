.header{
    display: flex;
    justify-content: space-between;
}

.logo{
    width: 10rem;
    height: 6rem;
    
}

.logo-text{
    color: white;
    margin: 10px;
    font-size: 25px;
    font-weight: 900;
    font-style: italic;
    letter-spacing: 2px;
    background: linear-gradient(to right, var(--darkGrey), transparent);
    padding: 2px;
    transition: .3s;
    cursor: pointer;
    padding-left: 10px;
    border-top-left-radius: 15px;
}

.logo-text:hover{
    word-spacing: 10px;

}

.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}


.header-menu>li:hover{
    cursor: pointer;
    color: var(--orange);
}


@media screen and (max-width:768px){
    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
        flex-direction: column;
        background: var(--appColor);
        padding: 2rem;
        border-radius: 10px;
    }
    .logo-text{
        font-size: 20px;
        margin-left: 0;
    }

}
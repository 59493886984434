.testimonials{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}

.left-t{
    display: flex;
    flex: 1 1;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
}

.left-t>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
}

.left-t>:nth-child(2),
.left-t>:nth-child(3)
{
    font-size: 3rem;
    font-weight: bold;
}

.left-t>:nth-child(4){
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}

.right-t{
    flex: 1;
    position: relative;
}

.right-t>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem; 

}

.right-t>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 1px solid orange;
    right: 9rem;
    background: transparent;
    top: 0.9rem;
}


.right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
    background: var(--planCard);
    top: 4rem;
    right: 7rem;

}


.arrows{
    display: flex;
    position: absolute;
    gap: 1rem;
    bottom: 1rem;
    left: 3rem;

}

.arrows>img{
    width: 1rem;
    cursor: pointer;
    border: 1px solid var(--lightgray);
    padding: 7px;
    border-radius: 50%;
    transition: .3s;
}

.arrows>img:hover{
    transform: scale(1.09);
}

@media screen and (max-width:768px){
    .testimonials{
        flex-direction: column;
    }

    .right-t{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .right-t>div{
        position: relative;
        display: none;
    }
    .right-t>img{
        top: 0;
        right: 0;
        position: relative;
        align-self: center;
    }
    .right-t>:last-child{
        display: block;
        top: 3rem;
       gap: 2rem;
        left: 0;
        
    }
    .arrows>img{
        gap: 1rem;
        /* padding: 0 15px; */
        padding: 7px ;
        margin: 0 15px;
        border-radius: 50%;
        
    }
}